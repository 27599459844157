import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "../../components/Api";
import { privateApiPOST } from "../../components/PrivateRoute";
import { showPaymentDialog } from "../../redux/app/appSlice";
import { roundOfInteger, validateObjectData } from "../../utils";
import { useNavigate } from "react-router-dom";

const RazorPayment = () => {
  const { isShowPaymentMode, profileData, selectedPlan } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // REMOVE - Payment dialog
  const hidePaymentDialog = () => {
    dispatch(showPaymentDialog(false));
  };

  // CREATE ORDER
  const handleCreateOrder = (payload) => {
    privateApiPOST(Api.createOrder, payload)
      .then((response) => {
        const { status, data } = response;
        console.log("Create order API RESPONSE --->", response);
        //window.location.reload();
        navigate("/");
      })
      .catch((error) => {
        console.log("Create order API ERROR --->", error);
      });
  };

  // PAYMENT
  const handleInitiatePayment = () => {
    const RZP_KEYS = {
      key_id: "rzp_live_dZRIISi26H8yKB",
      key_secret: "EjHGpTDG82xen2XiVpn31CAt",
    };

    const RZP_TESTMODE_KEYS = {
      key_id: "rzp_test_0x9zztTXJNg6JQ",
      key_secret: "3y9asxqSlgeeku5uH4tRYLDV",
    };

    let prefillObj = {
      name: "",
      email: "",
      contact: null,
    };

    const prefillCustomerData = validateObjectData(profileData)
      ? {
          ...prefillObj,
          name: profileData.first_name,
          email: profileData.email,
          contact: null,
        }
      : prefillObj;

    const amount = roundOfInteger(selectedPlan.discounted_price);
    var options = {
      key: RZP_KEYS.key_id,
      key_secret: RZP_KEYS.key_secret,
      amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "AForAI",
      description: "Transaction",
      order_receipt: "order_receipt_1",
      //order_id: "order_Jwnw5oZgf0w8Vz", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        const { razorpay_payment_id } = response;
        const currTimeInMs = new Date().getTime();
        let tempObj = {};

        tempObj["plan_days"] = selectedPlan.days;
        tempObj["plan_details"] = selectedPlan;
        tempObj["razorpay_payment_id"] = razorpay_payment_id;
        tempObj["amount_paid"] = amount;
        tempObj["order_id"] = `TNORDER${Math.floor(currTimeInMs / 1000)}`;
        //tempObj["subscription_end_date"] = "2024-12-30";
        handleCreateOrder(tempObj);
        hidePaymentDialog();
      },
      prefill: prefillCustomerData,
      notes: {
        address: "AFORAI Corporate Office",
      },
      theme: {
        color: "#11b34d",
      },
    };

    var rz = new window.Razorpay(options);
    rz.open();
  };

  useEffect(() => {
    if (isShowPaymentMode) {
      handleInitiatePayment();
    }

    return () => {
      hidePaymentDialog();
    };
  }, [isShowPaymentMode]);
};

export default RazorPayment;
