import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loadable from "../components/Loadable";
import PrivateRoute from "../components/PrivateRoute";
import MainLayout from "../layouts/MainLayout";
import config from "../config";

const HomePage = Loadable(lazy(() => import("../views/Home/home.page")));
// const PDFViewerPage = Loadable(lazy(() => import("../views/PDFViewer")));
const InfoPage = Loadable(lazy(() => import("../views/InfoPage/info.page")));
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));
const RegisterPage = Loadable(lazy(() => import("../views/auth/Register")));
const QpAnalysisPage = Loadable(
  lazy(() => import("../views/QuestionPaperAnalysis/qpa.page"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate replace to={config.defaultPath} />} /> */}
      <Route path="/" element={<MainLayout />}>
        <Route index element={<QpAnalysisPage />} />
        <Route path="keywords" element={<HomePage />} />
      </Route>
      {/* <Route
        path="/pdf-viewer"
        element={
          <PrivateRoute>
            <PDFViewerPage />
          </PrivateRoute>
        }
      /> */}
      <Route path="/info" element={<InfoPage />} />

      <Route path="/login" element={<LoginPage />} />
      <Route path="/freesignup" element={<RegisterPage />} />
    </Routes>
  );
};

export default AppRoutes;
