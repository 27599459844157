import React from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogContent, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { IconInfoCircle } from "@tabler/icons-react";

const useStyles = makeStyles((theme) => ({
  customPaper: {
    maxWidth: "650px",
    backgroundColor: "#efede4",
  },
  actionBtns: {
    minWidth: "100px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  actionBtnClose: {
    color: "#d98403",
    borderColor: "rgb(254 157 11)",
  },
}));

const CustomDialog = ({
  icon: IconComponent,
  iconColor = "#fe9d0b",
  open,
  title,
  children,
  actionButtonText,
  closeButtonText,
  handleActionClick,
  handleClose,
  ...others
}) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      open={open}
      //TransitionComponent={Zoom}
      //transitionDuration={500}
      classes={{
        paper: classes.customPaper,
      }}
      {...others}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <IconComponent color={iconColor} size={70} stroke={1} />
        <DialogTitle
          sx={{ padding: 0, fontSize: "2.6rem", mt: "20px", color: "#000000" }}
        >
          {title}
        </DialogTitle>
        {children ? children : null}
      </DialogContent>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  icon: PropTypes.elementType,
  iconColor: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  actionButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  handleClose: PropTypes.func,
  handleActionClick: PropTypes.func,
};

CustomDialog.defaultProps = {
  icon: IconInfoCircle,
  open: false,
  actionButtonText: "Confirm",
  closeButtonText: "",
  handleActionClick: () => {
    return;
  },
  handleClose: () => {
    return;
  },
};

export default CustomDialog;
