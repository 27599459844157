import React, { useState, useEffect } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  disableInitialLoading,
  setProfileData,
  setSubsDaysLeft,
  setUserLoggedIn,
  resetAppSlice,
  setActiveTab,
} from "../../redux/app/appSlice";
import Footer from "./Footer";
import Modals from "./Modals";
import Api from "../../components/Api";
import { privateApiGET } from "../../components/PrivateRoute";
import { calculateDaysLeft } from "../../utils";
import RenewModal from "./Modals/RenewModal";
import { MAIN_TABS_ID } from "../../constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const MainLayout = ({}) => {
  const { initialAppLoading, isUserLoggedIn } = useSelector(
    (state) => state.app
  );
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  // FETCH - Profile data
  const handleFetchProfileData = () => {
    let tempObj = { loading: true, data: {} };
    dispatch(setProfileData(tempObj));
    privateApiGET(Api.profile)
      .then((response) => {
        //console.log("Fetch profile API RESPONSE --->", response);
        const { data, status } = response;
        if (status === 200) {
          const subsEndDate = data?.subscription_end_date;
          const daysLeft = calculateDaysLeft(subsEndDate);
          dispatch(setSubsDaysLeft(daysLeft));
          dispatch(setProfileData({ ...tempObj, loading: false, data }));

          // Subscription Expired - Show Renew Modal
          if ((daysLeft && daysLeft < 3) || daysLeft === 0) {
            // console.log("Subscription has expired.", daysLeft);
            const renewAlert = sessionStorage.getItem("renewAlertShown");
            if (!renewAlert) {
              setOpenDialog(true);
              sessionStorage.setItem("renewAlertShown", true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("Fetch profile API ERROR --->", error);
        const { status } = error.response;
        if (status === 401) {
          handleLogoutRedirect();
        } else {
          dispatch(setProfileData({ loading: false, data: {} }));
          dispatch(setSubsDaysLeft(null));
        }
      });
  };

  const handleLogoutRedirect = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("renewAlertShown");
    dispatch(resetAppSlice());
    navigate("/");
    // dispatch(setUserLoggedIn(false));
    // dispatch(setProfileData({ loading: false, data: {} }));
  };

  useEffect(() => {
    dispatch(disableInitialLoading(false));
    const isToken = localStorage.getItem("token");
    if (isToken) dispatch(setUserLoggedIn(true));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(disableInitialLoading(false));
    if (isUserLoggedIn) {
      handleFetchProfileData();
      dispatch(setActiveTab(2));
    }
  }, [isUserLoggedIn]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {/*  Renew - Modal  */}
      {openDialog && (
        <RenewModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          handleRenewToday={() => {
            setOpenDialog(false);
            navigate(`/keywords#${MAIN_TABS_ID.B_TAB}`);
          }}
        />
      )}

      {!initialAppLoading && (
        <>
          <Navbar />
          <div className="main-section">
            <Outlet />
          </div>
          <Footer />
          <Modals />
        </>
      )}
    </>
  );
};

export default MainLayout;
