import { useState, useEffect } from "react";
import { privateApiGET } from "../components/PrivateRoute";
import { getURLQueryParamValue, validateObjectData } from "../utils";
import Api from "../components/Api";

const useFetchPDFs = (activeKeyword) => {
  const initialState = {
    isFetching: false,
    keywordFiles: [],
    fetchNextFilesUrl: null,
    fetchPrevFilesUrl: null,
    totalKeywordFiles: null,
    visiblePageNums: "",
    isUnauthorized: false,
    results: [],
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (validateObjectData(activeKeyword)) {
      setState((prev) => ({
        ...prev,
        isFetching: true,
      }));

      //console.log("activeKeyword HOOK", activeKeyword);

      const { params } = activeKeyword;

      const offsetParam = getURLQueryParamValue(params, "offset");
      const offset = offsetParam ? parseInt(offsetParam) : 0;
      const url = `${Api.getKeywordFiles}${params}`;

      privateApiGET(url)
        .then((response) => {
          //console.log("Fetch files API RESPONSE --->", response);
          const { data, status } = response;
          if (status === 200) {
            const filesArr = data.results?.map((obj) => obj.file);
            const currPageNum = data.count
              ? `${offset + 1} - ${offset + data.results.length}`
              : 0;
            setState((prev) => ({
              ...prev,
              totalKeywordFiles: data.count,
              keywordFiles: filesArr,
              fetchPrevFilesUrl: data.previous || null,
              fetchNextFilesUrl: data.next || null,
              visiblePageNums: currPageNum,
              isFetching: false,
              results: data.results,
            }));
          }
        })
        .catch((error) => {
          console.log("Fetch files API ERROR --->", error);

          const { status } = error.response;
          setState((prev) => ({
            ...prev,
            keywordFiles: [],
            fetchPrevFilesUrl: null,
            fetchNextFilesUrl: null,
            visiblePageNums: "",
            isFetching: false,
            isUnauthorized: status === 401 ? true : false,
            results: [],
          }));
        });
    }
  }, [activeKeyword]);

  return state;
};

export default useFetchPDFs;
