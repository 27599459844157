import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";

import PDFViewerPage from "../../../../views/PDFViewer";
import { useDispatch, useSelector } from "react-redux";
import { validateObjectData } from "../../../../utils";
import { setViewerKeyword } from "../../../../redux/app/appSlice";

const ViewerModal = () => {
  const { activeViewerKeyword } = useSelector((state) => state.app);
  const [openViewer, setOpenViewer] = useState(false);
  const dispatch = useDispatch();

  //CLOSE - Modal
  const handleClose = () => {
    dispatch(setViewerKeyword({}));
    setOpenViewer(false);
  };

  // Trigger when keyword selected to view
  useEffect(() => {
    if (
      validateObjectData(activeViewerKeyword) &&
      (activeViewerKeyword.id !== null || activeViewerKeyword.name !== null)
    ) {
      setOpenViewer(true);
    }
  }, [activeViewerKeyword]);

  return openViewer ? (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#000000",
        },
      }}
      fullScreen
      open={openViewer}
    >
      <PDFViewerPage handleViewerClose={handleClose} />
    </Dialog>
  ) : null;
};

export default ViewerModal;
