import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

const NavLogo = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <img
      onClick={() => navigate("/")}
      src="/static/images/PYQ.svg"
      alt="TNPSC PYQ"
      style={{ height: !matchDownMd ? 60 : 45, cursor: "pointer" }}
    />
  );
};

export default NavLogo;
