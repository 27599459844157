import { createSlice } from "@reduxjs/toolkit";
import { MAIN_TABS_ID } from "../../constants";

const tabLabels = [MAIN_TABS_ID.A_TAB, MAIN_TABS_ID.B_TAB, MAIN_TABS_ID.C_TAB];
const initialState = {
  isMaintenanceMode: false,
  initialAppLoading: true,
  isUserLoggedIn: false,
  isProfileDataLoading: false,
  profileData: {},
  activeTab: {
    id: 1,
    label: tabLabels[1],
  },
  openAuthModal: false,
  showPaymentAfterLogin: false,
  isShowPaymentMode: false,
  paymentAmout: 0,
  selectedPlan: {},
  activeViewerKeyword: {},
  subsDaysLeft: null,
  downloadedSamples: 0,
  isSampleExceed: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    disableInitialLoading: (state) => {
      state.initialAppLoading = false;
    },
    setUserLoggedIn: (state, { payload }) => {
      state.isUserLoggedIn = payload;
    },
    setProfileData: (state, { payload }) => {
      const { loading, data } = payload;
      state.isProfileDataLoading = loading;
      state.profileData = data;
    },
    showAuthDialog: (state, { payload }) => {
      const { openAuth, paymentAfterLogin } = payload;
      state.openAuthModal = openAuth;
      state.showPaymentAfterLogin = paymentAfterLogin;
    },
    showPaymentDialog: (state, { payload }) => {
      state.isShowPaymentMode = payload;
    },
    setPaymentAmount: (state, { payload }) => {
      state.paymentAmout = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = {
        id: payload,
        label: tabLabels[payload],
      };
    },
    setSelectedPlan: (state, { payload }) => {
      state.selectedPlan = payload;
    },
    setViewerKeyword: (state, { payload }) => {
      state.activeViewerKeyword = payload;
    },
    setSubsDaysLeft: (state, { payload }) => {
      state.subsDaysLeft = payload;
    },
    setDownloadedSamples: (state, { payload }) => {
      state.downloadedSamples += payload;
    },
    setSampleExceed: (state, { payload }) => {
      state.isSampleExceed = payload;
    },
    resetAppSlice: () => {
      return initialState;
    },
  },
});

export const {
  resetAppSlice,
  disableInitialLoading,
  setProfileData,
  showAuthDialog,
  setUserLoggedIn,
  showPaymentDialog,
  setPaymentAmount,
  setActiveTab,
  setSelectedPlan,
  setViewerKeyword,
  setSubsDaysLeft,
  setDownloadedSamples,
  setSampleExceed,
} = appSlice.actions;
export default appSlice.reducer;
