import React, { useState } from "react";

// Material UI
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Stack,
  Button,
  useMediaQuery,
  useTheme,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import NavLogo from "./NavLogo";
import { AccountCircle, Today } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import {
  IconLogout,
  IconFileSearch,
  IconListSearch,
  IconHome,
} from "@tabler/icons-react";
import SocialIcons from "./SocialIcons";
import HeroSection from "./HeroSection";
import { useSelector, useDispatch } from "react-redux";
import { resetAppSlice } from "../../../redux/app/appSlice";
import Api from "../../../components/Api";
import { privateApiPOST } from "../../../components/PrivateRoute";
import { MAIN_TABS_ID } from "../../../constants";

const Navbar = () => {
  const { isUserLoggedIn, subsDaysLeft, activeTab, profileData } = useSelector(
    (state) => state.app
  );
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // CLICK - Logout
  const handleClickLogout = () => {
    privateApiPOST(Api.logout)
      .then((res) => {
        //console.log("Logout API response ---> ", res);
        handleLogoutRedirect();
      })
      .catch((error) => {
        console.log("Logout API Error ---> ", error);
        handleLogoutRedirect();
      });
  };

  const handleLogoutRedirect = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("renewAlertShown");
    dispatch(resetAppSlice());
    navigate("/");
  };

  return (
    <>
      {activeTab.label === MAIN_TABS_ID.A_TAB && <SocialIcons />}
      <AppBar
        sx={{
          backgroundColor: (theme) => theme.palette.common.black,
          boxShadow: "none",
          padding: "10px 0",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters variant="dense">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <NavLogo />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Stack direction="row" spacing={3}>
                  {/* <Button
                    component={Link}
                    to="/"
                    variant="text"
                    sx={{ py: 0 }}
                    startIcon={<IconHome />}
                  >
                    Home
                  </Button> */}
                  <Button
                    component={Link}
                    to="/keywords"
                    variant="text"
                    sx={{ py: 0 }}
                    startIcon={<IconListSearch />}
                  >
                    Keywords
                  </Button>
                  {isUserLoggedIn ? (
                    <>
                      {subsDaysLeft !== null && subsDaysLeft >= 0 ? (
                        <Chip
                          icon={
                            <Today
                              sx={{
                                width: "20px",
                                height: "20px",
                                ml: "10px !important",
                              }}
                            />
                          }
                          label={`${subsDaysLeft} days left`}
                          variant="outlined"
                          color="info"
                          sx={{
                            borderRadius: "10px",
                            backgroundColor: "#fff",
                            color: "#006da9",
                          }}
                        />
                      ) : null}

                      <div>
                        <IconButton
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          color="primary"
                          sx={{ p: 0 }}
                        >
                          <AccountCircle />
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              minWidth: "160px",
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                              "& li": {
                                fontSize: "16px",
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            sx={{ pointerEvents: "none" }}
                            disableTouchRipple
                          >
                            Welcome&nbsp;{" "}
                            <strong className="fw-semibold">
                              {profileData.first_name} {profileData.last_name}
                            </strong>
                          </MenuItem>
                          <MenuItem onClick={handleClickLogout}>
                            <IconLogout color={"#777777"} className="me-5" />{" "}
                            logout
                          </MenuItem>
                        </Menu>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        sx={{
                          py: "4px",
                          minWidth: "100px",
                          borderColor: "#ffad33",
                        }}
                        // startIcon={<AccountCircle />}
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={"/login"}
                      >
                        Login
                      </Button>
                      <Button
                        sx={{
                          py: "4px",
                          ml: "15px !important",
                        }}
                        // startIcon={<AccountCircle />}
                        variant="contained"
                        component={Link}
                        to={"/freesignup"}
                      >
                        Join for free
                      </Button>
                    </>
                  )}
                </Stack>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <HeroSection />
    </>
  );
};

export default Navbar;
