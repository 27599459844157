const BASE_URL = "https://app.pagir.in"; //http://127.0.0.1:8000
export default {
  // Auth endpoints
  login: BASE_URL + "/api/v1/dj-rest-auth/login/",
  register: BASE_URL + "/api/v1/dj-rest-auth/registration/",
  logout: BASE_URL + "/api/v1/dj-rest-auth/logout/",
  profile: BASE_URL + "/api/v1/profile",
  // forgotPassword: BASE_URL+"/api/v1/user/forgot-password/",
  // resetPassword: BASE_URL+"/api/v1/user/reset-password/",

  getSubjects: BASE_URL + "/api/v1/subjects/",
  getTopics: BASE_URL + "/api/v1/topics/?limit=200",
  getKeywords: BASE_URL + "/api/v1/keywords/", // ?topic__subject=SUBJECT_ID, is_subscribed=True, ?search=KEYWORD_NAME
  getCartTotal: BASE_URL + "/api/v1/calculate-cart-total",

  createOrder: BASE_URL + "/api/v1/orders/",
  getKeywordFiles: BASE_URL + "/api/v1/get-files-for-keyword", //keyword={keyword_id}&limit={limit} //search_term={keyword_id}, //&exam_name=Test&exam_date=20240724&subject=test
  generateSamplePDF: BASE_URL + "/generate-sample/?term=", // https://app.pagir.in/generate-sample/?search_result=7095

  getQPDataByYear: BASE_URL + "/api/v1/get-papers-by-year?year=", // https://app.pagir.in/api/v1/get-papers-by-year?year=2024

  //unused
  getKeywordsBySubject: `/api/v1/keywords/?topic__subject=SUBJECT_ID`,
  getSubscribedKeyowrds: `/api/v1/keywords/?is_subscribed=True`,
  getKeywordSampleFile: `/api/v1/keywords/KEYWORD_ID/generate-sample`,
  getSearchKeyword: `/api/v1/keywords/?search=KEYWORD_NAME`,
  getExams: "/api/v1/exams/",
};
