import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { IconTool, IconTools } from "@tabler/icons-react";

const MaintenanceModal = () => {
  const { isMaintenanceMode } = useSelector((state) => state.app);

  return (
    <>
      {isMaintenanceMode && (
        <Dialog
          open={isMaintenanceMode}
          scroll={"paper"}
          aria-labelledby="cart-dialog-title"
          aria-describedby="cart-dialog-content"
          fullWidth
          BackdropProps={{
            sx: { backgroundColor: "rgba(0, 0, 0, 0.9)" },
          }}
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "600px",
              borderRadius: "15px",
            },
          }}
        >
          <DialogContent sx={{ textAlign: "center" }}>
            <IconTool color={"#fe9d0b"} size={80} stroke={1} />
            <DialogTitle
              sx={{
                padding: 0,
                fontSize: "3rem",
                mt: "20px",
                color: "#000000",
                marginBottom: "15px",
              }}
            >
              We are under maintenance!
            </DialogTitle>
            <Typography sx={{ mb: "15px", color: "#444444" }}>
              We're performing scheduled maintenance to improve our site. We
              apologize for any inconvenience and appreciate your patience.
              Please check back soon!
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default MaintenanceModal;
