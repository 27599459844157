import React from "react";

// Material UI
import { Typography, Button } from "@mui/material";
import { IconSquareXFilled } from "@tabler/icons-react";
import CustomDialog from "../../../components/CustomDialog";
import { useSelector } from "react-redux";

const RenewModal = ({ open, handleClose, handleRenewToday }) => {
  const { subsDaysLeft } = useSelector((state) => state.app);

  return (
    <CustomDialog
      open={open}
      title={
        subsDaysLeft === 0
          ? "Subscription ends today!"
          : subsDaysLeft < 0
          ? "Subscription has expired!"
          : `Renew Your Subscription: ${subsDaysLeft} Days Left!`
      }
      sx={{
        "& .MuiBackdrop-root": { backgroundColor: "rgba(0, 0, 0, 0.9)" },
        "& .MuiPaper-root": { maxWidth: "600px", borderRadius: "20px" },
      }}
    >
      <IconSquareXFilled
        size={35}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
          color: "#b5b09a",
        }}
        onClick={handleClose}
      />
      <Typography sx={{ mt: "10px", mb: "20px" }}>
        Keep your access and benefits without interruption.
      </Typography>

      <Button
        onClick={handleRenewToday}
        sx={{ mb: "10px" }}
        variant="contained"
      >
        Renew Today
      </Button>
    </CustomDialog>
  );
};

export default RenewModal;
