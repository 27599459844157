import React from "react";
import RazorPayment from "../../../views/RazorPayment";
import MaintenanceModal from "./MaintenanceModal";
import ViewerModal from "./ViewerModal";

const Modals = () => {
  return (
    <>
      <MaintenanceModal />
      <RazorPayment />
      <ViewerModal />
    </>
  );
};

export default Modals;
