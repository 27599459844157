import React, { useEffect, useState, useRef } from "react";
import {
  IconButton,
  Button,
  ButtonBase,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  IconPlus,
  IconMinus,
  IconCircleChevronLeft,
  IconCircleChevronRight,
  IconSquareXFilled,
  IconShoppingBagHeart,
} from "@tabler/icons-react";

import { SpecialZoomLevel, Worker, Viewer } from "@react-pdf-viewer/core";
import { PDFDocument } from "pdf-lib";

import clsx from "clsx";

import { useSelector, useDispatch } from "react-redux";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

// Zoom Plugin
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

// Page Navigation
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { validateObjectData, validateArrayData } from "../../utils";

import CustomDialog from "../../components/CustomDialog";
import useFetchPDFs from "../../hooks/useFetchPDFs";
import { setViewerKeyword } from "../../redux/app/appSlice";
import { Link, useNavigate } from "react-router-dom";
import { MAIN_TABS_ID } from "../../constants";

const PDFViewerPage = ({ handleViewerClose }) => {
  const { activeViewerKeyword, subsDaysLeft } = useSelector(
    (state) => state.app
  );
  const {
    isFetching,
    keywordFiles,
    fetchNextFilesUrl,
    fetchPrevFilesUrl,
    totalKeywordFiles,
    visiblePageNums,
    isUnauthorized,
  } = useFetchPDFs(activeViewerKeyword);

  const [state, setState] = useState({
    isMerging: false,
    mergedFile: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Zoom
  const zoomPluginInstance = zoomPlugin();
  const { Zoom, CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  // Page Navigation
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  // CLICK - Disable right click
  const handleRightClick = (event) => {
    event.preventDefault();
  };

  // KEY PRESS - Handle key press
  const handleKeyPress = (event) => {
    if (event.key === "F12" || event.key === "PrtSc") {
      event.preventDefault();
    }
  };

  // PAGINATION - Load paginated files
  const handleLoadPaginatedFiles = (url) => {
    if (subsDaysLeft == null || subsDaysLeft < 0) {
      setOpenDialog(true);
      return;
    }

    const params = url.split("?")[1];
    setState((prev) => ({
      ...prev,
      mergedFile: null,
    }));

    dispatch(
      setViewerKeyword({
        ...activeViewerKeyword,
        params: `?${params}`,
      })
    );
  };

  // MERGE - Combine Multiple Pdfs
  const handleMergePDFs = async (pdfUrls) => {
    setState((prev) => ({
      ...prev,
      isMerging: true,
    }));

    try {
      const mergedPdf = await PDFDocument.create();

      for (const pdfUrl of pdfUrls) {
        const pdfBytes = await fetch(pdfUrl).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch PDF from ${pdfUrl}`);
          }
          return res.arrayBuffer();
        });
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }

      const mergedPdfBytes = await mergedPdf.save();
      setState((prev) => ({
        ...prev,
        mergedFile: new Uint8Array(mergedPdfBytes),
        isMerging: false,
      }));
    } catch (error) {
      setError(error.message);
      setState((prev) => ({
        ...prev,
        isMerging: false,
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      mergedFile: null,
    }));

    // Add event listeners when component mounts
    //window.addEventListener("contextmenu", handleRightClick);
    window.addEventListener("keydown", handleKeyPress);

    // Remove event listeners when component unmounts
    return () => {
      //window.removeEventListener("contextmenu", handleRightClick);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (validateArrayData(keywordFiles)) {
      headerRef.current?.scrollIntoView();
      handleMergePDFs(keywordFiles);
    }
  }, [keywordFiles]);

  return (
    <>
      {openDialog && (
        <CustomDialog
          icon={IconShoppingBagHeart}
          open={openDialog}
          title="Unlock More Pages with Your Support!"
          sx={{
            "& .MuiBackdrop-root": { backgroundColor: "rgba(0, 0, 0, 0.9)" },
          }}
        >
          <IconSquareXFilled
            size={35}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              color: "#b5b09a",
            }}
            onClick={() => {
              setOpenDialog(false);
            }}
          />

          <Typography sx={{ my: "20px" }}>
            Subscribe to our plans starting at just 1 rupee per day
          </Typography>
          {/* <Button sx={{ mb: "10px", mr: 2 }} variant="text">
            Close
          </Button> */}
          <Button
            sx={{ mb: "10px", minWidth: "170px" }}
            variant="contained"
            onClick={() => {
              setOpenDialog(false);
              handleViewerClose();
              navigate(`/keywords#${MAIN_TABS_ID.B_TAB}`);
            }}
          >
            Support Us
          </Button>
        </CustomDialog>
      )}

      {!validateObjectData(activeViewerKeyword) && (
        <div className="min-vh-100 w-100 d-flex align-items-center justify-content-center">
          <CircularProgress size={30} thickness={4} color="primary" />
        </div>
      )}
      {validateObjectData(activeViewerKeyword) && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div ref={headerRef}></div>
          <div className="rpv-core__viewer pdf-viewer">
            <div className="flex-wrap pdf-viewer__topbar justify-content-between py-10">
              <div className="d-flex align-items-center mb-10 mb-md-0">
                <IconButton
                  onClick={handleViewerClose}
                  color="primary"
                  aria-label="Back"
                >
                  <IconSquareXFilled />
                </IconButton>
                <span className="text-white">{activeViewerKeyword.name}</span>
              </div>
              <div className="pv-topbar__navigation">
                <CurrentPageLabel>
                  {(props) => (
                    <>
                      <IconCircleChevronLeft
                        onClick={() =>
                          handleLoadPaginatedFiles(fetchPrevFilesUrl)
                        }
                        color="#ffad33"
                        role="button"
                        className={clsx({
                          "me-10": true,
                          "pe-none opacity-50":
                            !state.isMerging && !fetchPrevFilesUrl,
                        })}
                      />

                      <Typography
                        sx={{
                          minWidth: "55px",
                          display: "inline-block",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                        component="span"
                        color="text.white"
                      >
                        {/* {`${props.currentPage + 1}  ${
                          props.pageLabel === `${props.currentPage + 1}`
                            ? ""
                            : `${props.pageLabel}`
                        } - ${props.numberOfPages} of ${
                          state.totalKeywordFiles
                        } Pages`} */}

                        {`${visiblePageNums} of ${totalKeywordFiles} Questions`}
                      </Typography>

                      <IconCircleChevronRight
                        onClick={() =>
                          handleLoadPaginatedFiles(fetchNextFilesUrl)
                        }
                        color="#ffad33"
                        role="button"
                        className={clsx({
                          "ms-10": true,
                          "pe-none opacity-50":
                            !state.isMerging && !fetchNextFilesUrl,
                        })}
                      />
                    </>
                  )}
                </CurrentPageLabel>
              </div>

              <div className="pv-topbar__zoom">
                <ZoomIn>
                  {(props) => (
                    <IconButton
                      color="primary"
                      onClick={props.onClick}
                      aria-label="Zoom In"
                    >
                      <IconPlus />
                    </IconButton>
                  )}
                </ZoomIn>
                <CurrentScale>
                  {(props) => (
                    <Typography
                      sx={{
                        minWidth: "55px",
                        display: "inline-block",
                        verticalAlign: "middle",
                        textAlign: "center",
                      }}
                      component="span"
                      color="text.white"
                    >{`${Math.round(props.scale * 100)}%`}</Typography>
                  )}
                </CurrentScale>
                <ZoomOut>
                  {(props) => (
                    <IconButton
                      color="primary"
                      onClick={props.onClick}
                      aria-label="Zoom Out"
                    >
                      <IconMinus />
                    </IconButton>
                  )}
                </ZoomOut>
                <Zoom>
                  {(props) => (
                    <Button
                      onClick={() => props.onZoom(SpecialZoomLevel.ActualSize)}
                      variant="contained"
                      size="small"
                      sx={{
                        padding: "2px 10px",
                        fontSize: "16px",
                        marginLeft: "15px",
                        minWidth: "105px",
                      }}
                      className="d-none d-lg-inline-flex"
                    >
                      Actual Size
                    </Button>
                  )}
                </Zoom>
              </div>
            </div>
            <div
              className="pdf-viewer__body"
              style={{
                flex: 1,
                overflow: "hidden",
                color: "#fff",
              }}
            >
              {state.isMerging && (
                <div className="min-vh-100 w-100 d-flex align-items-center justify-content-center">
                  <div className="text-center" style={{ marginTop: "-50px" }}>
                    <CircularProgress size={30} thickness={4} color="primary" />{" "}
                    <p className="mb-0 mt-10 fw-medium">
                      Please Wait...Preparing
                    </p>
                  </div>
                </div>
              )}
              {state.mergedFile && (
                <Viewer
                  fileUrl={state.mergedFile}
                  defaultScale={0.6}
                  plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                />
              )}

              {error && (
                <div className="min-vh-100 w-100 d-flex align-items-center justify-content-center">
                  <Alert severity="error" variant="filled">
                    Failed to fetch the PDF file from server
                  </Alert>
                </div>
              )}

              {!isFetching && !validateArrayData(keywordFiles) && (
                <div className="min-vh-100 w-100 d-flex align-items-center justify-content-center">
                  <Alert severity="info" variant="filled">
                    {isUnauthorized ? (
                      <>
                        Please{" "}
                        <ButtonBase
                          sx={{ textDecoration: "underline !important" }}
                          component={Link}
                          to={"/login"}
                        >
                          Login
                        </ButtonBase>{" "}
                        to view the searched keywords
                      </>
                    ) : (
                      "No keywords were found. Try different keywords"
                    )}
                  </Alert>
                </div>
              )}
            </div>
          </div>
        </Worker>
      )}
    </>
  );
};

export default PDFViewerPage;
